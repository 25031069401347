import { Modal } from "react-bootstrap";
import GroupSelect from "./GroupSelect";

function GroupSelectDialog({ show, title, groups, onSelected, onCancel }) {
    return (
        <Modal
            show={show}
            onHide={onCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GroupSelect groups={groups} onSelected={onSelected} />
            </Modal.Body>
        </Modal>
    );
}

export default GroupSelectDialog;
