import { useCallback, useState } from "react";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { Button, Form, FormGroup, InputGroup } from "react-bootstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faCheck } from "@fortawesome/free-solid-svg-icons";

function ProfilePhoneInput({ clientApi, phone, setPhone, phoneConfirmed, changeEnabled, updatePhoneConfirmed }) {
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');

    const confirmPhone = useCallback(async () => {
        try {
            if (confirmationCode === '') {
                await clientApi.confirmPhone({ phone: phone });
                setConfirmationOpen(true);
                showNotification(t("profilePhoneConfirmationSent"), 'success');
            } else {
                await clientApi.confirmPhone({ code: confirmationCode });
                setConfirmationOpen(false);
                showNotification(t("profilePhoneConfirmed"), 'success');
                updatePhoneConfirmed();
            }
        } catch (error) {
            console.error(error);
            showNotification(t("profilePhoneConfirmationError") + ": " + error.message, 'danger');
        }
    }, [clientApi, confirmationCode, phone, updatePhoneConfirmed]);

    return (
        <FormGroup className="mt-3">
            <Form.Label><Trans>profilePhone</Trans></Form.Label>

            <InputGroup>
                <Form.Control
                    type="text"
                    placeholder={t("profilePhoneInput")}
                    value={phone}
                    disabled={!changeEnabled || confirmationOpen}
                    onChange={e => setPhone(e.target.value)}
                />

                {phoneConfirmed && (
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                    </InputGroup.Text>
                )}
                
                {changeEnabled && (
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => confirmPhone()}
                    >
                        <Trans>profilePhoneSendCode</Trans>
                    </Button>
                )}
            </InputGroup>

            <Form.Text className="text-muted text-center">
                {phoneConfirmed ? (
                    <Trans>profilePhoneConfirmed</Trans>
                ) : (
                    <Trans>profilePhoneNotConfirmed</Trans>
                )}
            </Form.Text>

            {confirmationOpen && (
                <div className="d-flex justify-content-end mt-3">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faArrowCircleRight} className="" />

                        <Form.Control
                            type="text"
                            placeholder={t("profilePhoneConfirmationCodePlaceholder")}
                            className="ms-2"
                            value={confirmationCode}
                            onChange={e => setConfirmationCode(e.target.value)}
                        />

                        <Button
                            variant="primary"
                            size="sm"
                            className="ms-2"
                            onClick={() => confirmPhone(confirmationCode)}
                        >
                            <Trans>profilePhoneConfirm</Trans>
                        </Button>
                    </div>
                </div>
            )}
        </FormGroup>
    )
}

export default ProfilePhoneInput;
