import { useEffect, useMemo, useState } from "react";
import useDebounce from "../../utils/useDebounce";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import useEmployeeApi from "../../app/useEmployeeApi";
import { showNotification } from "../../utils/notification";
import Loader from "../global/Loader";

function EmployeeSelect({ employees, active, handleActive, selected, handleSelected }) {
    const employeeApi = useEmployeeApi();

    const [curEmployees, setCurEmployees] = useState(employees);

    const [filterStr, setFilterStr, debouncedFilterStr] = useDebounce("", 500);

    const filteredEmployees = useMemo(() => {
        if (!curEmployees) {
            return [];
        }

        if (!debouncedFilterStr) {
            return curEmployees;
        }

        // Split filter string by spaces
        const filterWords = debouncedFilterStr.split(" ");

        return curEmployees.filter((employee) => {
            // All of the filter words must be in the employee first name, last name or other fields
            return filterWords.every((filterWord) => {
                return (
                    employee.first_name?.toLowerCase()?.includes(filterWord.toLowerCase()) ||
                    employee.last_name?.toLowerCase()?.includes(filterWord.toLowerCase()) ||
                    employee.employee_number?.toLowerCase()?.includes(filterWord.toLowerCase()) ||
                    employee.department?.toLowerCase()?.includes(filterWord.toLowerCase()) ||
                    employee.profession?.toLowerCase()?.includes(filterWord.toLowerCase())
                );
            });
        });
    }, [curEmployees, debouncedFilterStr]);

    useEffect(() => {
        async function fetchEmployees() {
            try {
                const list = await employeeApi.getEmployees(debouncedFilterStr);
                setCurEmployees(list);
            } catch (e) {
                console.error(e);
                showNotification(t("errorLoadingEmployees"), "danger");
            }
        }

        if (!employees) {
            fetchEmployees();
        }
    }, [debouncedFilterStr, employeeApi, employees]);

    function getEmployeeExtraText(employee) {
        let items = [];

        if (employee.profession) {
            items.push(employee.profession);
        }

        if (employee.department) {
            items.push(employee.department);
        }

        if (items.length > 0) {
            return ` (${items.join(", ")})`;
        } else {
            return "";
        }
    }

    return (
        <div>
            {/* Filter input */}
            <InputGroup className="my-3 text-sm">
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                    type="text"
                    placeholder={t("employeeSearch")}
                    value={filterStr}
                    onChange={(e) => setFilterStr(e.target.value)}
                    onFocus={() => handleActive(true)}
                />
            </InputGroup>

            {/* Employee list with scroll */}
            {active && (
                curEmployees ? (
                    <div className="d-flex flex-column my-3" style={{ height: "400px", overflowY: "auto" }}>
                        {filteredEmployees.map((employee) => (
                            <div key={employee.id}>
                                <Button
                                    variant="link"
                                    onClick={() => handleSelected(employee)}
                                >{employee.first_name} {employee.last_name}</Button>
                                {getEmployeeExtraText(employee)}
                            </div>
                        ))}
                    </div>
                ) : (
                    <Loader />
                )
            )}

            {/* Selected employee */}
            {!active && selected && (
                <div className="bg-primary-subtle border rounded d-flex justify-content-between align-items-center p-2">
                    {selected.first_name} {selected.last_name} ({selected.department})
                </div>
            )}
        </div>
    );
}

export default EmployeeSelect;
