import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCheckCircle, faEdit, faFile, faFloppyDisk, faShare, faSync, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import Header from '../components/global/Header';
import CartItem from '../components/cart/CartItem';
import CurrencyFormatter from '../components/global/CurrencyFormatter';
import { showNotification } from '../utils/notification';
import CartLoader from '../components/cart/CartLoader';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { Collapse, Dropdown } from 'react-bootstrap';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import Footer from '../components/global/Footer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { shareUrl } from '../utils/share';
import useCartApi from '../app/useCartApi';
import useCartData from '../app/useCartData';
import ExportCartForm from '../components/cart/ExportCartForm';
import InputDialog from '../components/global/InputDialog';

const Cart = () => {
    const params = useParams();

    const { cart, setCartId, setCartItems, setCartName, setCartSubmitted, cartHistory, setCartHistory } = useCartData();
    const cartApi = useCartApi();

    const [cartWasReset, setCartWasReset] = useState(false);

    const [exportCartOpen, setExportCartOpen] = useState(false);
    const [renameModalShow, setRenameModalShow] = useState(false);

    const wasLoaded = useRef(false);

    const totalPrice = useMemo(() => cart.cartItems.reduce((total, item) => total + item.price * item.quantity, 0), [cart.cartItems]);

    const synchronizeCart = useCallback(() => {
        const fn = async () => {
            // create new cart
            const newCartData = await cartApi.create();
            const newCartId = newCartData.cartId;
            setCartId(newCartId);

            // save current items to new cart
            await cartApi.save(newCartId, cart.cartItems);

            // Save to history
            setCartHistory({
                ...cartHistory,
                [newCartData.cartId]: {
                    id: newCartData.cartId,
                    name: newCartData.name,
                    submitted: newCartData.submitDate ? true : false,
                }
            });
        };

        fn()
            .then(() => {
                showNotification(t("Shopping list synchronized"), "success");
            })
            .catch((error) => {
                console.log(error);
                showNotification(t("Error synchronizing shopping list"), "danger");
            });
    }, [cart.cartItems, cartHistory, cartApi, setCartId, setCartHistory]);

    const loadCart = (newCartId, skipNotification) => {
        cartApi.load(newCartId.trim())
            .then((cartData) => {
                setCartId(cartData.cartId);
                setCartItems(cartData.items);
                setCartName(cartData.name);
                setCartSubmitted(cartData.submitDate ? true : false);

                // Save to history
                setCartHistory({
                    ...cartHistory,
                    [cartData.cartId]: {
                        id: cartData.cartId,
                        name: cartData.name,
                        submitted: cartData.submitDate ? true : false,
                    }
                });

                if (!skipNotification) {
                    showNotification(t("Shopping list loaded"), "success");
                }
            })
            .catch((error) => {
                console.log(error);
                showNotification(t("Error loading shopping list"), "danger");
            })
    };

    const submitCart = () => {
        cartApi.submit(cart.cartId, cart.cartItems, cart.cartName)
            .then(() => {
                setCartSubmitted(true);

                // Save to history
                setCartHistory({
                    ...cartHistory,
                    [cart.cartId]: {
                        id: cart.cartId,
                        name: cart.cartName,
                        submitted: true,
                    }
                });

                showNotification(t("Shopping list submitted"), "success");
            })
            .catch((error) => {
                console.log(error);
                showNotification(t("Error submitting shopping list"), "danger");
            })
    };

    const clearCart = () => {
        setCartItems([]);

        if (cart.cartId) {
            cartApi.save(cart.cartId, [])
                .then(() => {
                    showNotification(t("Shopping list saved"), "success");
                })
                .catch((error) => {
                    console.log(error);
                    showNotification(t("Error saving shopping list"), "danger");
                })
        }
    };

    const resetCart = () => {
        setCartId(null);
        setCartItems([]);
        setCartName('');
        setCartSubmitted(false);

        setCartWasReset(true);
    };

    const handleUpdateQuantity = (cartItem, quantity) => {
        const newCartItems = cart.updateQuantity(cartItem, quantity);
        setCartItems(newCartItems);

        if (cart.cartId) {
            cartApi.save(cart.cartId, newCartItems)
                .then(() => {
                    showNotification(t("Shopping list saved"), "success");
                })
                .catch((error) => {
                    console.log(error);
                    showNotification(t("Error saving shopping list"), "danger");
                })
        }
    };

    const handleRemoveProduct = (cartItem) => {
        const newCartItems = cart.remove(cartItem);
        setCartItems(newCartItems);

        if (cart.cartId) {
            cartApi.save(cart.cartId, newCartItems)
                .then(() => {
                    showNotification(t("Shopping list saved"), "success");
                })
                .catch((error) => {
                    console.log(error);
                    showNotification(t("Error saving shopping list"), "danger");
                })
        }
    };

    function renameCart(newName) {
        setCartName(newName);

        if (cart.cartId) {
            cartApi.save(cart.cartId, cart.cartItems, newName)
                .then(() => {
                    // Save to history
                    setCartHistory({
                        ...cartHistory,
                        [cart.cartId]: {
                            id: cart.cartId,
                            name: newName,
                            submitted: cart.cartSubmitted,
                        }
                    });

                    showNotification(t("Shopping list saved"), "success");
                })
                .catch((error) => {
                    console.log(error);
                    showNotification(t("Error saving shopping list"), "danger");
                })
        }
    }

    function shareCart(cartId) {
        // Generate share link
        const link = cartApi.makeShareLink(cartId);

        // Share using system share API or clipboard
        shareUrl(t("Share shopping list"), t("Share shopping list"), link);
    }

    useEffect(() => {
        if (cartWasReset) {
            synchronizeCart();
            setCartWasReset(false);
        }
    }, [cartWasReset, synchronizeCart]);

    useEffect(() => {
        // load cart on mount only (once)
        if (wasLoaded.current) {
            return;
        }

        wasLoaded.current = true;

        if (params.cartId) {
            loadCart(params.cartId);
        } else if (cart.cartId) {
            loadCart(cart.cartId, true);
        } else {
            synchronizeCart();
        }
    });

    return (
        <>
            <Helmet>
                <title>{t("Shopping list")} - FeetApp</title>
            </Helmet>

            <Header />

            <div className="container-md mt-5">
                <h3>
                    <div className="d-flex align-items-center">
                        <div>
                            <Trans>Shopping list</Trans> <span className="fw-bold">{cart.cartName || cart.cartId || ""}</span> {cart.cartSubmitted && <FontAwesomeIcon icon={faCheckCircle} className="text-success" />}
                        </div>

                        <InputDialog 
                            show={renameModalShow} 
                            title={<Trans>Rename shopping list</Trans>} 
                            enterText={<Trans>Save</Trans>}
                            value={cart.cartName}
                            onChange={newName => {
                                setRenameModalShow(false);
                                renameCart(newName);
                            }}
                            onCancel={() => setRenameModalShow(false)}
                        />

                        {cart.cartId && (
                            <>
                                <div className='ms-auto'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-menu">
                                            <FontAwesomeIcon icon={faBars} /> <span className="d-none d-sm-inline"><Trans>Action</Trans></span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <DropdownItem onClick={() => setRenameModalShow(true)}><FontAwesomeIcon icon={faEdit} /> <Trans>Rename</Trans></DropdownItem>
                                            <DropdownItem onClick={() => setExportCartOpen(e => !e)}><FontAwesomeIcon icon={faFile} /> <Trans>Export</Trans></DropdownItem>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <div className="ms-2">
                                    <button className='btn btn-secondary' onClick={() => shareCart(cart.cartId)}>
                                        <FontAwesomeIcon icon={faShare} /><span className='d-none d-sm-inline'> <Trans>Share</Trans></span>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </h3>

                <div className="d-flex justify-content-end">
                    <Collapse in={exportCartOpen} className="mt-3">
                        <div id="exportMeasurements">
                            <ExportCartForm cartApi={cartApi} cartId={cart.cartId} />
                        </div>
                    </Collapse>
                </div>

                <div className='text-muted'><Trans>cartId</Trans>: {cart.cartId}</div>

                <div className="mt-2">
                    <CartLoader loadCallback={loadCart} cartHistory={cartHistory} />
                </div>

                <div className='mt-3'>
                    {cart.cartItems?.length > 0 ? (
                        <>
                            {cart.cartItems.map((cartItem) => (
                                <CartItem cartItemData={cartItem} updateCallback={handleUpdateQuantity} removeCallback={handleRemoveProduct} key={cartItem} />
                            ))}

                            <hr className="hr" />

                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end">
                                        <p className="text-size-3 fw-bold"><Trans>Total</Trans>: <CurrencyFormatter amount={totalPrice} /></p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="text-center">
                            <Trans>Shopping list is empty</Trans>
                        </div>
                    )}

                    <div className='d-flex justify-content-between my-3 align-items-center'>
                        <button className='btn btn-primary me-2' onClick={resetCart}><FontAwesomeIcon icon={faFile} /><span className="d-none d-sm-inline"> <Trans>New</Trans></span></button>
                        <button className='btn btn-outline-danger me-2' onClick={clearCart}><FontAwesomeIcon icon={faTrashAlt} /><span className="d-none d-sm-inline"> <Trans>Clear</Trans></span></button>

                        {cart.cartId ? (
                            <div className='d-flex ms-auto'>
                                <button className='btn btn-primary ms-2' onClick={submitCart}><FontAwesomeIcon icon={faFloppyDisk} /> <Trans>Submit</Trans></button>
                            </div>
                        ) : (
                            <div className='d-flex ms-auto align-items-center'>
                                {/* <Trans>Synchronize shopping list for export: </Trans> */}
                                <button className='btn btn-link btn-sm' onClick={synchronizeCart}><FontAwesomeIcon icon={faSync} /> <Trans>Synchronize</Trans></button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Cart;
