import { t } from "i18next";
import { useCallback, useMemo, useState } from "react";
import { Button, Form, FormGroup, InputGroup } from "react-bootstrap";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import Captcha from "../global/Captcha";

function LoginResetPassword({ clientApi }) {
    const [email, setEmail] = useState("");

    const [captchaOpen, setCaptchaOpen] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const [captchaCode, setCaptchaCode] = useState('');

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');

    const resetAvailable = useMemo(() => {
        return email.length > 0;
    }, [email]);

    const resetPassword = useCallback(async () => {
        try {
            if (confirmationCode === '') {
                await clientApi.resetPassword({ email, captchaToken, captchaCode });
                setConfirmationOpen(true);
                showNotification(t("passwordResetCodeSent"), 'success');

                // Reset captcha
                setCaptchaToken("");
                setCaptchaCode("");
            } else {
                await clientApi.resetPassword({ code: confirmationCode, email });
                setConfirmationOpen(false);
                showNotification(t("passwordResetCodeConfirmed"), 'success');
            }
        } catch (error) {
            console.error(error);
            showNotification(t("passwordResetError") + ": " + error.message, 'danger');
        }
    }, [confirmationCode, clientApi, email, captchaToken, captchaCode]);

    return (
        <Form>
            {captchaOpen && (
                <Captcha
                    clientApi={clientApi}
                    captchaToken={captchaToken}
                    setCaptchaToken={setCaptchaToken}
                    captchaCode={captchaCode}
                    setCaptchaCode={setCaptchaCode}
                />
            )}

            <FormGroup className="mt-3">
                <Form.Label><Trans>email</Trans></Form.Label>

                <InputGroup>
                    <Form.Control
                        type="email"
                        placeholder={t("emailInput")}
                        value={email}
                        onFocus={() => setCaptchaOpen(true)}
                        onChange={e => setEmail(e.target.value)}
                    />

                    {!confirmationOpen && (
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => resetPassword()}
                        >
                            <Trans>resetPasswordSendCode</Trans>
                        </Button>
                    )}
                </InputGroup>
            </FormGroup>

            {confirmationOpen && (
                <div className="d-flex justify-content-end mt-3 align-items-center">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faArrowCircleRight} className="" />

                        <Form.Control
                            type="text"
                            placeholder={t("passwordResetConfirmationCodePlaceholder")}
                            className="ms-2"
                            value={confirmationCode}
                            onChange={e => setConfirmationCode(e.target.value)}
                        />

                        <Button
                            className="ms-2"
                            disabled={!resetAvailable}
                            variant="danger"
                            onClick={resetPassword}
                        >
                            <Trans>resetPassword</Trans>
                        </Button>
                    </div>
                </div>
            )}
        </Form>
    );
}

export default LoginResetPassword;
