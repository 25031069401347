import { useCallback, useMemo, useState } from "react";
import useClientMeasurementApi from "../../app/useClientMeasurementApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { Button, Form } from "react-bootstrap";
import { Trans } from "react-i18next";

function AddGroupForm({ handleAdded }) {
    const clientMeasurementApi = useClientMeasurementApi();

    const [name, setName] = useState('');

    const addButtonEnabled = useMemo(() => !!name, [name]);

    const addGroup = useCallback(async () => {
        if (!clientMeasurementApi.ready()) {
            showNotification(t("addGroupApiNotReady"), 'error');
            return;
        }

        try {
            const group = await clientMeasurementApi.addGroup(name);
            handleAdded({
                group_id: group.group_id,
                name: group.name
            });
            setName('');
        } catch (err) {
            showNotification(t("addGroupFailed") + ": " + err, 'danger');
        }
    }, [clientMeasurementApi, name, handleAdded]);

    return (
        <Form>
            <Form.Group className="mb-3">
                <Form.Label htmlFor="nameInput"><Trans>addGroupName</Trans></Form.Label>
                <Form.Control type="text" id="nameInput" value={name} onChange={e => setName(e.target.value)} />
            </Form.Group>

            <Button variant="primary" disabled={!addButtonEnabled} onClick={addGroup}><Trans>addGroup</Trans></Button>
        </Form>
    );
}

export default AddGroupForm;
