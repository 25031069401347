import { Helmet } from 'react-helmet-async';

import Header from '../components/global/Header';
import CartMeasurements from "../components/measurement/CartMeasurements";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlus, faQrcode } from "@fortawesome/free-solid-svg-icons";
import LocalMeasurements from "../components/measurement/LocalMeasurements";
import { useCallback, useEffect, useState } from "react";
import { Button, Collapse, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router";
import useLocalStorageState from "use-local-storage-state";
import { Link } from "react-router-dom";
import Footer from "../components/global/Footer";
import ClientMeasurements from '../components/measurement/ClientMeasurements';
import EmployeesMeasurements from '../components/measurement/EmployeesMeasurements';
import useEmployeeApi from '../app/useEmployeeApi';
import { showNotification } from '../utils/notification';
import useClientMeasurementApi from '../app/useClientMeasurementApi';
import AddGroupForm from '../components/measurement/AddGroupForm';

const Measurements = () => {
    const { measurementIds } = useParams();
    const [sessionId,] = useLocalStorageState('sessionId');
    const [cartId] = useLocalStorageState('cartId');
    const [cartMeasurementsOpen, setCartMeasurementsOpen] = useState(true);

    const clientMeasurementApi = useClientMeasurementApi();
    const [groups, setGroups] = useState(null);

    const employeeApi = useEmployeeApi();
    const [employees, setEmployees] = useState(null);

    const [activeTabKey, setActiveTabKey] = useState('defaultGroup');

    const updateGroups = useCallback(async () => {
        if (clientMeasurementApi.ready()) {
            try {
                const groups = await clientMeasurementApi.getGroups();
                setGroups(groups);
                return groups;
            } catch (error) {
                console.log(error);
                showNotification(t("measurementsFetchGroupsFailed") + ": " + error, 'danger');
            }
        } else {
            setGroups(null);
        }
    }, [clientMeasurementApi]);

    const updateEmployees = useCallback(async () => {
        if (employeeApi.ready()) {
            try {
                const employees = await employeeApi.getEmployees();
                setEmployees(employees);
            } catch (error) {
                console.log(error);
                showNotification(t("employeesFetchFailed") + ": " + error, 'danger');
            }
        } else {
            setEmployees(null);
        }
    }, [employeeApi]);

    const handleGroupAdded = useCallback(async (group) => {
        const newGroups = await updateGroups();

        // If added group is in the list, switch the tab
        if (newGroups && newGroups.find(g => g.group_id === group.group_id)) {
            setActiveTabKey(group.group_id);
        }
    }, [updateGroups]);

    const handleUpdateGroup = useCallback(async (group) => {
        try {
            await clientMeasurementApi.updateGroup(group);
            await updateGroups();
        } catch (error) {
            console.log(error);
            showNotification(t("measurementsUpdateGroupFailed") + ": " + error, 'danger');
        }
    }, [clientMeasurementApi, updateGroups]);

    const handleDeleteGroup = useCallback(async (group) => {
        try {
            // Delete group
            await clientMeasurementApi.deleteGroup(group.group_id);

            // Update groups
            await updateGroups();

            // Update active tab
            if (activeTabKey === group.group_id) {
                setActiveTabKey('defaultGroup');
            }
        } catch (error) {
            console.log(error);
            showNotification(t("measurementsDeleteGroupFailed") + ": " + error, 'danger');
        }
    }, [activeTabKey, clientMeasurementApi, updateGroups]);

    useEffect(() => {
        const delayedUpdate = setTimeout(() => {
            updateGroups();
            updateEmployees();
        }, 200);
        return () => clearTimeout(delayedUpdate);
    }, [updateEmployees, updateGroups]);

    return (
        <>
            <Helmet>
                <title>{t("Measurements")} - FeetApp</title>
            </Helmet>

            <Header />

            <div className="container my-3">
                {/* Helper info */}
                <div className="alert alert-info">
                    <FontAwesomeIcon icon={faInfoCircle} /> <Trans>useFeetAppMobileOrScanner</Trans>
                    <Link to="/applications" className="ms-1">
                        <FontAwesomeIcon icon={faQrcode} /> <Trans>Applications</Trans>
                    </Link>
                </div>

                {sessionId ? (
                    <Tabs
                        activeKey={activeTabKey}
                        onSelect={(k) => setActiveTabKey(k)}
                        id="client-measurements"
                        className="my-3"
                        mountOnEnter
                        unmountOnExit
                    >
                        <Tab eventKey="defaultGroup" title={t("clientMeasurementsDefaultGroup")}>
                            <ClientMeasurements
                                group={null}
                                groups={groups}
                                measurementIds={measurementIds}
                                employees={employees}
                            />
                        </Tab>

                        {groups && groups.length > 0 && (
                            groups.map((group) => (
                                <Tab
                                    key={group.group_id}
                                    eventKey={group.group_id}
                                    title={group.name}
                                >
                                    <ClientMeasurements
                                        group={group}
                                        groups={groups}
                                        employees={employees}
                                        handleUpdateGroup={handleUpdateGroup}
                                        handleDeleteGroup={handleDeleteGroup}
                                    />
                                </Tab>
                            ))
                        )}

                        <Tab eventKey="addGroup" title={<FontAwesomeIcon icon={faPlus} />}>
                            <div className='mt-2'>
                                <AddGroupForm handleAdded={handleGroupAdded} />
                            </div>
                        </Tab>

                        <Tab eventKey="employeesMeasurements" title={t("employeesMeasurements")}>
                            <EmployeesMeasurements employees={employees} />
                        </Tab>
                    </Tabs>
                ) : (
                    <>
                        <h3><Trans>Local measurements</Trans></h3>

                        <LocalMeasurements measurementIds={measurementIds} />

                        <Button variant="link" className="my-3" onClick={() => setCartMeasurementsOpen(!cartMeasurementsOpen)}
                            aria-controls="cart-measurements" aria-expanded={cartMeasurementsOpen}>
                            {cartMeasurementsOpen ? <Trans>Hide shopping list measurements</Trans> : <Trans>Show shopping list measurements</Trans>}
                        </Button>

                        <Collapse in={cartMeasurementsOpen}>
                            <div id="cart-measurements">
                                <div className="my-3 d-flex justify-content-between">
                                    <h3><Trans>Shopping list measurements</Trans></h3>

                                    {cartId && (
                                        <h5 className='text-muted'>{cartId}</h5>
                                    )}
                                </div>

                                <CartMeasurements />
                            </div>
                        </Collapse>
                    </>
                )}
            </div>

            <Footer />
        </>
    );
};

export default Measurements;
