import { Modal } from "react-bootstrap";
import MeasurementSelect from "./MeasurementSelect";

function MeasurementSelectDialog({ show, title, onSelected, onCancel }) {
    return (
        <Modal
            show={show}
            size="lg"
            onHide={onCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MeasurementSelect onSelected={onSelected}/>
            </Modal.Body>
        </Modal>
    );
}

export default MeasurementSelectDialog;
