import { useCallback, useEffect, useMemo, useState } from "react";
import useClientMeasurementApi from "../../app/useClientMeasurementApi";
import { getInformativeMeasurements } from "../../app/measurement";
import { apiGetMeasurements } from "../../app/measurementApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import Loader from "../global/Loader";
import { Button, Form, InputGroup } from "react-bootstrap";
import useDebounce from "../../utils/useDebounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function MeasurementSelect({ onSelected }) {
    const clientMeasurementApi = useClientMeasurementApi();

    const [clientMeasurements, setClientMeasurements] = useState(null);
    const [groups, setGroups] = useState(null);

    const [filterStr, setFilterStr, debouncedFilterStr] = useDebounce("", 500);

    const [measurements, setMeasurements] = useState(null);
    const measurementData = useMemo(() => {
        if (!measurements || !clientMeasurements || !groups) {
            return null;
        }

        // Build a list of measurement and client measurement (from informative measurements only)
        let md = getInformativeMeasurements(measurements).map(m => ({
            ...m,
            clientMeasurement: clientMeasurements.find(cm => cm.measurement_id === m.measurementId)
        }));

        // Append group data
        md = md.map(m => ({
            ...m,
            group: m.clientMeasurement ? groups.find(g => g.group_id === m.clientMeasurement.group_id) : null
        }));

        // Filter by humanName, measurement id, group name and date string
        md = md.filter(m => {
            return m?.humanInfo?.name?.toLowerCase().includes(debouncedFilterStr.toLowerCase()) ||
                m.measurementId.toString().toLowerCase().includes(debouncedFilterStr.toLowerCase()) ||
                m.group?.name?.toLowerCase().includes(debouncedFilterStr.toLowerCase()) ||
                m.dateString?.toLowerCase().includes(debouncedFilterStr.toLowerCase());
        });

        // Sort by group, then measurementId
        md.sort((a, b) => {
            if (a.group?.name < b.group?.name) {
                return -1;
            }
            if (a.group?.name > b.group?.name) {
                return 1;
            }
            return a.measurementId - b.measurementId;
        });

        return md;
    }, [measurements, clientMeasurements, groups, debouncedFilterStr]);

    const fetchMeasurements = useCallback(async () => {
        if (!clientMeasurementApi.ready()) {
            return;
        }

        try {
            // Fetch client measurements
            const cms = await clientMeasurementApi.getMeasurements({ allGroups: true });
            setClientMeasurements(cms);

            // Extract array of measurement ids
            let mIds = cms.map(m => m.measurement_id);

            // Fetch measurements
            const ms = await apiGetMeasurements(mIds);
            setMeasurements(ms);

            // Fetch groups
            const gms = await clientMeasurementApi.getGroups();
            setGroups(gms);
        } catch (err) {
            console.log(err);
            showNotification(t("clientMeasurementsFetchFailed") + ": " + err, 'danger');
        }
    }, [clientMeasurementApi]);

    useEffect(() => {
        const delayedFetch = setTimeout(() => {
            fetchMeasurements();
        }, 200);

        return () => clearTimeout(delayedFetch);
    }, [fetchMeasurements]);

    return (
        <div>
            {/* Filter input */}
            <InputGroup className="my-3 text-sm">
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                    type="text"
                    placeholder={t("measurementSelectSearch")}
                    value={filterStr}
                    onChange={(e) => setFilterStr(e.target.value)}
                />
            </InputGroup>

            {/* Measurement list with scroll */}
            {measurementData ? (
                <div className="d-flex flex-column my-3 align-items-center" style={{ height: "400px", overflowY: "auto" }}>
                    {measurementData.map(measurement => (
                        <div className="d-flex w-100 align-items-center" key={measurement.measurementId}>
                            <div>
                                <Button
                                    variant="link"
                                    onClick={() => onSelected(measurement)}
                                >
                                    {measurement?.humanInfo?.name ? measurement.humanInfo.name : t("Measurement") + " " + measurement.measurementId}
                                </Button>
                            </div>

                            <div className="d-none d-md-block ms-1"> {measurement?.dateString && "(" + measurement.dateString + ")"}</div>
                            <div className="ms-1"><i className="text-muted"><small>{measurement.measurementId}</small></i></div>

                            <div className="d-none d-md-block ms-auto">
                                {measurement.group?.name ? measurement.group.name : t("clientMeasurementsDefaultGroup")}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <Loader />
            )}
        </div>

    );
}

export default MeasurementSelect;
