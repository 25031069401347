class ClientMeasurementApi {
    constructor(sessionId) {
        this.sessionId = sessionId;
    }

    ready() {
        return !!this.sessionId;
    }

    async addMeasurement(measurementId, groupId, ignoreAlreadyAdded) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/add_measurement';

        let formData = new FormData();
        formData.append('measurement_id', measurementId);

        if (groupId) {
            formData.append('group_id', groupId);
        }

        if (ignoreAlreadyAdded) {
            formData.append('ignore_already_added', 'True');
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async addMeasurements(measurementIds, groupId, ignoreAlreadyAdded) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/add_measurements';

        let formData = new FormData();
        for (const i in measurementIds) {
            formData.append('measurement_id', measurementIds[i]);
        }

        if (groupId) {
            formData.append('group_id', groupId);
        }

        if (ignoreAlreadyAdded) {
            formData.append('ignore_already_added', 'True');
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async getMeasurements({ groupId, allGroups }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_measurements';

        let params = new URLSearchParams();
        params.append('id_only', 'False');
        if (groupId) {
            params.append('group_id', groupId);
        }
        if (allGroups) {
            params.append('all_groups', 'True');
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async addGroup(name) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/add_group';

        let formData = new FormData();
        formData.append('name', name);

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async getGroup(groupId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_group';

        let params = new URLSearchParams();
        params.append('group_id', groupId || '');

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async getGroups() {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/get_groups';

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, { headers: headers });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async updateGroup(group) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/update_group';

        let formData = new FormData();
        formData.append('group_id', group.group_id);
        formData.append('name', group.name);

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }

        const data = await response.json();
        return data;
    }

    async setGroupForMeasurements(measurementIds, groupId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/set_group';

        let formData = new FormData();
        for (const i in measurementIds) {
            formData.append('measurement_id', measurementIds[i]);
        }
        if (groupId) {
            formData.append('group_id', groupId);
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async deleteGroup(groupId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/delete_group';

        let formData = new FormData();
        formData.append('group_id', groupId);

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        })

        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }

    async exportMeasurements({ groupId, allGroups, format, measurementIds, cartExportParams }) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/export_measurements';

        let formData = new FormData();
        formData.append('format', format);
        formData.append('attachment', true);

        if (measurementIds && measurementIds.length > 0) {
            for (const i in measurementIds) {
                formData.append('measurement_id', measurementIds[i]);
            }
        }

        for (const key in cartExportParams) {
            formData.append(key, cartExportParams[key]);
        }

        if (groupId) {
            formData.append('group_id', groupId);
        }
        if (allGroups) {
            formData.append('all_groups', 'True');
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.blob();
        return data;
    }

    async deleteMeasurement(measurementId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/delete_measurement';

        let formData = new FormData();
        formData.append('measurement_id', measurementId);

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }

    async deleteMeasurements(measurementIds) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/client/delete_measurements';

        let formData = new FormData();
        for (const i in measurementIds) {
            formData.append('measurement_id', measurementIds[i]);
        }

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: formData
        });

        if (!response.ok) {
            const responseText = await response.text();
            throw new Error(responseText);
        }
    }
}

export default ClientMeasurementApi;
