import { useCallback, useMemo, useState } from "react";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { checkPhoneNumber } from "../../utils/phoneNumber";
import { Button, Form, FormGroup, InputGroup } from "react-bootstrap";
import Captcha from "../global/Captcha";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

function LoginPhone({ clientApi, handleLogin }) {
    const [phone, setPhone] = useState('');

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');

    const [captchaOpen, setCaptchaOpen] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const [captchaCode, setCaptchaCode] = useState('');

    const phoneValid = useMemo(() => {
        return phone.length > 0 && checkPhoneNumber(phone);
    }, [phone]);

    const askCode = useCallback(async () => {
        try {
            await clientApi.phone_login_ask(phone, captchaToken, captchaCode);
            setConfirmationOpen(true);
            showNotification(t("loginPhoneConfirmationSent"), 'success');
        } catch (error) {
            console.error(error);
            showNotification(t("loginPhoneConfirmationError") + ": " + error.message, 'danger');
        }
    }, [clientApi, captchaToken, captchaCode, phone]);

    const login = useCallback(async () => {
        try {
            const sessionId = await clientApi.phone_login(phone, confirmationCode);
            setConfirmationOpen(false);
            showNotification(t("loginPhoneConfirmed"), 'success');
            handleLogin(sessionId);
        } catch (error) {
            console.error(error);
            showNotification(t("loginPhoneConfirmationError") + ": " + error.message, 'danger');
        }
    }, [clientApi, confirmationCode, handleLogin, phone]);

    return (
        <Form>
            {captchaOpen && (
                <Captcha
                    clientApi={clientApi}
                    captchaToken={captchaToken}
                    setCaptchaToken={setCaptchaToken}
                    captchaCode={captchaCode}
                    setCaptchaCode={setCaptchaCode}
                />
            )}

            <FormGroup>
                <InputGroup>
                    <Form.Control
                        type="text"
                        placeholder={t("loginPhoneInput")}
                        value={phone}
                        disabled={confirmationOpen}
                        onFocus={() => setCaptchaOpen(true)}
                        onChange={e => setPhone(e.target.value)}
                    />

                    <Button
                        variant="primary"
                        disabled={!phoneValid}
                        onClick={() => askCode()}
                    >
                        <Trans>loginPhoneSendCode</Trans>
                    </Button>
                </InputGroup>

                {confirmationOpen && (
                    <div className="d-flex justify-content-end mt-3">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faArrowCircleRight} className="" />

                            <Form.Control
                                type="text"
                                placeholder={t("loginPhoneConfirmationCodePlaceholder")}
                                className="ms-2"
                                value={confirmationCode}
                                onChange={e => setConfirmationCode(e.target.value)}
                            />

                            <Button
                                variant="primary"
                                size="sm"
                                className="ms-2"
                                onClick={() => login()}
                            >
                                <Trans>loginPhoneConfirm</Trans>
                            </Button>
                        </div>
                    </div>
                )}
            </FormGroup>
        </Form >
    );
}

export default LoginPhone;
