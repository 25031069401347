import { Trans } from "react-i18next";
import useClientMeasurementApi from "../../app/useClientMeasurementApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getInformativeMeasurements } from "../../app/measurement";
import { apiGetMeasurements } from "../../app/measurementApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import Loader from "../global/Loader";
import { Alert, Button, Collapse, Form, Modal } from "react-bootstrap";
import AddMeasurementForm from "./AddMeasurementForm";
import EmployeeSelect from "../employee/EmployeeSelect";
import useEmployeeApi from "../../app/useEmployeeApi";
import ExportMeasurementsForm from "./ExportMeasurementsForm";
import MeasurementCards from "./MeasurementCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faExchangeAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmationDialog from "../global/ConfirmationDialog";
import InputDialog from "../global/InputDialog";
import GroupSelectDialog from "./GroupSelectDialog";

function ClientMeasurements({ group, groups, measurementIds, employees, handleUpdateGroup, handleDeleteGroup }) {
    const clientMeasurementApi = useClientMeasurementApi();
    const employeeApi = useEmployeeApi();

    const groupId = useMemo(() => group ? group.group_id : null, [group]);

    const [measurements, setMeasurements] = useState(null);
    const informativeMeasurements = useMemo(() => getInformativeMeasurements(measurements), [measurements]);

    const [selectedMeasurements, setSelectedMeasurements] = useState([]);

    const [newMeasurementsAdded, setNewMeasurementsAdded] = useState(false);
    const [exportMeasurementsOpen, setExportMeasurementsOpen] = useState(false);

    const [employeeSelectOpen, setEmployeeSelectOpen] = useState(false);
    const [employeeSelectMeasurement, setEmployeeSelectMeasurement] = useState(null);

    const [renameInputOpen, setRenameInputOpen] = useState(false);
    const [deleteGroupConfirmOpen, setDeleteGroupConfirmOpen] = useState(false);

    const [moveMeasurementsModalOpen, setMoveMeasurementsModalOpen] = useState(false);
    const [deleteMeasurementsConfirmOpen, setDeleteMeasurementsConfirmOpen] = useState(false);

    const addNewMeasurements = useCallback(async (clientMeasurementIds) => {
        if (!clientMeasurementApi.ready()) {
            return;
        }

        if (!measurementIds || newMeasurementsAdded) {
            return;
        }

        const newIds = measurementIds.split(',');
        let mIds = clientMeasurementIds;

        // Add all measurements that are not in the list
        for (const m in newIds) {
            if (!mIds.includes(newIds[m])) {
                await clientMeasurementApi.addMeasurement(newIds[m], groupId, true);
                mIds.push(newIds[m]);
            }
        }

        setNewMeasurementsAdded(true);
        return mIds;
    }, [clientMeasurementApi, groupId, measurementIds, newMeasurementsAdded]);

    const updateMeasurements = useCallback(async () => {
        if (!clientMeasurementApi.ready()) {
            return;
        }

        try {
            // Fetch client measurements
            const clientMeasurements = await clientMeasurementApi.getMeasurements({ groupId })

            // Extract array of measurement ids
            let mIds = clientMeasurements.map(m => m.measurement_id);

            // Add new measurements if given
            if (measurementIds && !newMeasurementsAdded) {
                mIds = await addNewMeasurements(mIds);
            }

            // Fetch measurements
            const ms = await apiGetMeasurements(mIds);
            setMeasurements(ms);
        } catch (err) {
            console.log(err);
            showNotification(t("clientMeasurementsFetchFailed") + ": " + err, 'danger');
        }
    }, [addNewMeasurements, clientMeasurementApi, groupId, measurementIds, newMeasurementsAdded]);

    const addMeasurement = useCallback(async (measurementId) => {
        if (!clientMeasurementApi.ready()) {
            showNotification(t("clientMeasurementsNotReady"), 'danger');
            return;
        }

        try {
            await clientMeasurementApi.addMeasurement(measurementId, groupId);
            await updateMeasurements();
        } catch (err) {
            console.log(err);
            showNotification(t("clientMeasurementsAddFailed") + ": " + err, 'danger');
        }
    }, [clientMeasurementApi, groupId, updateMeasurements]);

    const selectMeasurement = useCallback((measurementId, selected) => {
        if (selected) {
            setSelectedMeasurements([...selectedMeasurements, measurementId]);
        } else {
            setSelectedMeasurements(selectedMeasurements.filter(m => m !== measurementId));
        }
    }, [selectedMeasurements]);

    const selectAll = useCallback((selected) => {
        if (selected) {
            setSelectedMeasurements(measurements.map(m => m.measurementId));
        } else {
            setSelectedMeasurements([]);
        }
    }, [measurements]);

    const linkMeasurement = useCallback((measurement) => {
        setEmployeeSelectMeasurement(measurement);
        setEmployeeSelectOpen(true);
    }, [setEmployeeSelectMeasurement, setEmployeeSelectOpen]);

    const bindMeasurement = useCallback(async (employee) => {
        if (!employeeApi.ready()) {
            showNotification(t("employeesNotReady"), 'danger');
            return;
        }

        try {
            await employeeApi.addEmployeeMeasurement(employee.id, employeeSelectMeasurement);
            await updateMeasurements();
        } catch (err) {
            console.log(err);
            showNotification(t("employeesAddMeasurementFailed") + ": " + err, 'danger');
        }

        setEmployeeSelectMeasurement(null);
        setEmployeeSelectOpen(false);
    }, [employeeApi, employeeSelectMeasurement, updateMeasurements]);

    const moveSelected = useCallback(async (newGroupId) => {
        if (!clientMeasurementApi.ready()) {
            showNotification(t("clientMeasurementsNotReady"), 'danger');
            return;
        }

        try {
            await clientMeasurementApi.setGroupForMeasurements(selectedMeasurements, newGroupId);
            await updateMeasurements();
            setSelectedMeasurements([]);
        } catch (err) {
            console.log(err);
            showNotification(t("clientMeasurementsMoveFailed") + ": " + err, 'danger');
        }
    }, [clientMeasurementApi, selectedMeasurements, updateMeasurements]);

    const deleteMeasurement = useCallback(async (measurementId) => {
        if (!clientMeasurementApi.ready()) {
            showNotification(t("clientMeasurementsNotReady"), 'danger');
            return;
        }

        try {
            await clientMeasurementApi.deleteMeasurement(measurementId);
            await updateMeasurements();
        } catch (err) {
            console.log(err);
            showNotification(t("clientMeasurementsRemoveFailed") + ": " + err, 'danger');
        }
    }, [clientMeasurementApi, updateMeasurements]);

    const deleteSelected = useCallback(async () => {
        if (!clientMeasurementApi.ready()) {
            showNotification(t("clientMeasurementsNotReady"), 'danger');
            return;
        }

        try {
            await clientMeasurementApi.deleteMeasurements(selectedMeasurements);
            await updateMeasurements();
            setSelectedMeasurements([]);
        } catch (err) {
            console.log(err);
            showNotification(t("clientMeasurementsRemoveFailed") + ": " + err, 'danger');
        }
    }, [clientMeasurementApi, selectedMeasurements, updateMeasurements]);

    useEffect(() => {
        if (clientMeasurementApi.ready()) {
            updateMeasurements();

            // Update timer
            const timer = setInterval(() => {
                updateMeasurements();
            }, 5000);
            return () => clearInterval(timer);
        }
    }, [clientMeasurementApi, updateMeasurements]);

    return (
        measurements === null ? (
            <Loader />
        ) : (
            <>
                <Modal show={employeeSelectOpen} onHide={() => setEmployeeSelectOpen(false)}>
                    <Modal.Header>
                        <Modal.Title><Trans>selectEmployee</Trans></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EmployeeSelect
                            employees={employees}
                            active={true}
                            handleActive={setEmployeeSelectOpen}
                            handleSelected={bindMeasurement} />
                    </Modal.Body>
                </Modal>

                {newMeasurementsAdded && (
                    <Alert variant="success" className="my-3">
                        <Trans>Measurements added</Trans>
                    </Alert>
                )}

                <MeasurementCards
                    measurements={measurements}
                    selectedMeasurements={selectedMeasurements}
                    handleSelect={selectMeasurement}
                    handleRemove={deleteMeasurement}
                    handleLink={linkMeasurement} />

                {selectedMeasurements.length > 0 && (
                    <div className="mt-2 d-flex justify-content-center align-items-center">
                        <Form.Check
                            type="checkbox"
                            label={t("selectAll")}
                            onChange={(e) => selectAll(e.target.checked)} />
                        <Button
                            variant="outline-secondary"
                            className="ms-2"
                            onClick={() => setMoveMeasurementsModalOpen(true)}
                        ><FontAwesomeIcon icon={faExchangeAlt} /> <Trans>measurementsMove</Trans></Button>
                        <Button
                            variant="outline-danger"
                            className="ms-2"
                            onClick={() => setDeleteMeasurementsConfirmOpen(true)}
                        ><FontAwesomeIcon icon={faTrash} /> <Trans>measurementsRemoveSelected</Trans></Button>

                        <GroupSelectDialog
                            show={moveMeasurementsModalOpen}
                            title={t("measurementsMove")}
                            groups={groups}
                            onSelected={(group) => {
                                moveSelected(group ? group.group_id : null);
                                setMoveMeasurementsModalOpen(false);
                            }}
                            onCancel={() => setMoveMeasurementsModalOpen(false)}
                        />

                        <ConfirmationDialog
                            show={deleteMeasurementsConfirmOpen}
                            message={t("clientMeasurementsRemoveSelectedMessage")}
                            confirmText={t("clientMeasurementsRemoveSelectedButton")}
                            cancelText={t("clientMeasurementsRemoveSelectedCancel")}
                            onConfirm={() => {
                                deleteSelected();
                                setDeleteMeasurementsConfirmOpen(false);
                            }}
                            onCancel={() => setDeleteMeasurementsConfirmOpen(false)}
                        />
                    </div>
                )}

                <div className="d-flex justify-content-end align-items-center mt-3">
                    <div className="me-auto">
                        <AddMeasurementForm handleAdded={addMeasurement} />
                    </div>

                    {handleDeleteGroup && (
                        <div className="ms-2">
                            <Button variant="danger" onClick={() => setDeleteGroupConfirmOpen(true)}>
                                <FontAwesomeIcon icon={faTrash} /> <Trans>deleteGroup</Trans>
                            </Button>

                            <ConfirmationDialog
                                show={deleteGroupConfirmOpen}
                                title={t("clientMeasurementsDeleteGroup")}
                                message={t("clientMeasurementsDeleteGroupConfirm")}
                                confirmText={t("clientMeasurementsDeleteGroupButton")}
                                variant="danger"
                                onConfirm={() => {
                                    setDeleteGroupConfirmOpen(false);
                                    handleDeleteGroup(group);
                                }}
                                onCancel={() => setDeleteGroupConfirmOpen(false)}
                            />
                        </div>
                    )}

                    {handleUpdateGroup && (
                        <div className="ms-2">
                            <Button variant="secondary" onClick={() => setRenameInputOpen(true)}>
                                <FontAwesomeIcon icon={faEdit} /> <Trans>renameGroup</Trans>
                            </Button>

                            <InputDialog
                                show={renameInputOpen}
                                title={t("clientMeasurementsRenameGroup")}
                                message={t("clientMeasurementsRenameGroupMessage")}
                                enterText={t("clientMeasurementsRenameGroupButton")}
                                cancelText={t("clientMeasurementsRenameGroupCancel")}
                                value={group.name}
                                onChange={newName => {
                                    setRenameInputOpen(false);
                                    handleUpdateGroup({
                                        group_id: group.group_id,
                                        name: newName
                                    });
                                }}
                                onCancel={() => setRenameInputOpen(false)}
                            />
                        </div>
                    )}

                    {informativeMeasurements.length > 0 && (
                        <>
                            <Button className="ms-2" variant="secondary" onClick={() => setExportMeasurementsOpen(!exportMeasurementsOpen)}><Trans>exportMeasurements</Trans></Button>

                            <a href={"/catalog?" + new URLSearchParams({ groupId: groupId || '' })} className="btn btn-primary ms-2" rel="noopener noreferrer"><Trans>clientMeasurementsFitForGroup</Trans></a>
                        </>
                    )}
                </div>

                <div className="d-flex justify-content-end">
                    <Collapse in={exportMeasurementsOpen} className="mt-3">
                        <div id="exportMeasurements">
                            <ExportMeasurementsForm
                                clientMeasurementApi={clientMeasurementApi}
                                groupId={groupId}
                                measurementIds={selectedMeasurements} />
                        </div>
                    </Collapse>
                </div>
            </>
        )
    )
}

export default ClientMeasurements;
