import { useMemo } from "react";
import { getInformativeMeasurements } from "../../app/measurement";
import { Col, Row } from "react-bootstrap";
import MeasurementCard from "./MeasurementCard";
import { Trans } from "react-i18next";
import useDebounce from "../../utils/useDebounce";
import FilterStringInput from "../global/FilterStringInput";

function MeasurementCards({ measurements, findEmployee, selectedMeasurements, handleSelect, handleRemove, handleLink }) {
    const [filterStr, setFilterStr, debouncedFilterStr] = useDebounce("", 500);

    const informativeMeasurements = useMemo(() => getInformativeMeasurements(measurements), [measurements]);

    const filteredMeasurements = useMemo(() => {
        if (!informativeMeasurements) {
            return [];
        }

        if (!debouncedFilterStr) {
            return informativeMeasurements;
        }

        // Split filter string by spaces
        const filterWords = debouncedFilterStr.split(" ");

        return informativeMeasurements.filter((measurement) => {
            return filterWords.every((filterWord) => {
                return (
                    measurement?.humanInfo?.name?.toLowerCase()?.includes(filterWord.toLowerCase()) ||
                    measurement.measurementId?.toLowerCase()?.includes(filterWord.toLowerCase()) ||
                    measurement.dateString?.toLowerCase()?.includes(filterWord.toLowerCase())
                );
            })
        })
    }, [informativeMeasurements, debouncedFilterStr]);

    return (
        <>
            {filteredMeasurements.length > 0 && <FilterStringInput filterStr={filterStr} setFilterStr={setFilterStr} />}

            {
                filteredMeasurements.length > 0 ? (
                    <Row className="mt-3">
                        {filteredMeasurements.map(measurement => (
                            <Col sm={6} lg={3} className="mb-3 d-flex align-items-stretch" key={measurement.measurementId}>
                                <MeasurementCard
                                    measurement={measurement}                                
                                    employee={findEmployee && findEmployee(measurement.measurementId)} 
                                    selectedMeasurements={selectedMeasurements}
                                    handleSelect={handleSelect}
                                    handleRemove={handleRemove}
                                    handleLink={handleLink} />
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Row className="mt-3">
                        <Col xs={12}>
                            <Trans>noMeasurements</Trans>
                        </Col>
                    </Row>
                )
            }
        </>
    );
}

export default MeasurementCards;
