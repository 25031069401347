import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Trans } from "react-i18next";

function InputDialog({ show, title, message, enterText, cancelText, variant, value, onChange, onCancel }) {
    const [newValue, setNewValue] = useState(value);

    return (
        <Modal show={show} onHide={() => onCancel?.()}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3">
                {message && (
                    <Form.Label>{message}</Form.Label>
                )}
                <Form.Control type="text" value={newValue} onChange={e => setNewValue(e.target.value)} />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => onCancel?.()}>
                {cancelText || <Trans>Cancel</Trans>}
            </Button>
            <Button variant={variant || "primary"} onClick={() => onChange?.(newValue)}>
                {enterText || <Trans>Enter</Trans>}
            </Button>
        </Modal.Footer>
    </Modal>
    )
}

export default InputDialog;
