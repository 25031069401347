import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { apiGetMeasurements } from "../../app/measurementApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import Loader from "../global/Loader";
import FitnessRatingElement from "./FitnessRatingElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faXmark } from "@fortawesome/free-solid-svg-icons";

function FitnessEstimateManyElement({ fitnessEstimates, measurementIds, allMeasurementIds }) {
    const [allMeasurementData, setAllMeasurementData] = useState(null);

    const fitnessMeasurementIds = useMemo(() => {
        return fitnessEstimates?.map(fitnessEstimate => fitnessEstimate.measurementId)
    }, [fitnessEstimates]);

    const alreadyFitMeasurementIds = useMemo(() => {
        // Measurement IDS that are present in allMeasurementIds, but are not in measurementIds
        return allMeasurementIds?.filter(measurementId => measurementIds && !measurementIds.includes(measurementId));
    }, [allMeasurementIds, measurementIds]);

    const nonFitMeasurementIds = useMemo(() => {
        // Measurement IDS that are present in measurementIds, but not in fitnessMeasurementIds
        return measurementIds?.filter(measurementId => fitnessMeasurementIds && !fitnessMeasurementIds.includes(measurementId));
    }, [measurementIds, fitnessMeasurementIds]);

    const getHumanName = useCallback((measurementId) => {
        if (!allMeasurementData) {
            return "";
        }

        return allMeasurementData.find(measurement => measurement.measurementId === measurementId)?.humanInfo?.name || "";
    }, [allMeasurementData]);

    useEffect(() => {
        if (allMeasurementIds) {
            apiGetMeasurements(allMeasurementIds)
                .then(data => setAllMeasurementData(data))
                .catch(err => {
                    console.log(err);
                    showNotification(t("Error loading measurements") + ": " + err, 'danger');
                });
        }
    }, [allMeasurementIds]);

    return (
        <Container>
            {(allMeasurementData && fitnessEstimates && nonFitMeasurementIds && alreadyFitMeasurementIds) ? (
                <>
                    {/* Fitness estimate measurements */}
                    {fitnessEstimates.map(fitnessEstimate => (
                        <Row key={fitnessEstimate.measurementId}>
                            {/* Human name */}
                            <Col xs={4} md={6} lg={8}>{getHumanName(fitnessEstimate.measurementId)}</Col>

                            {/* Optimal size */}
                            <Col xs={4} md={3} lg={2}>{fitnessEstimate.size}</Col>

                            {/* Fitness rating */}
                            <Col xs={4} md={3} lg={2}><FitnessRatingElement rating={fitnessEstimate.fitnessRating} /></Col>
                        </Row>
                    ))}

                    {/* Measurements that are not in the fitness estimate because of being non fit */}
                    {nonFitMeasurementIds.map(measurementId => (
                        <Row key={measurementId}>
                            {/* Human name */}
                            <Col xs={4} md={6} lg={8}>{getHumanName(measurementId)}</Col>

                            {/* Red cross mark */}
                            <Col xs={4} md={3} lg={2}><FontAwesomeIcon icon={faXmark} className="text-danger" /></Col>

                            <Col xs={4} md={3} lg={2}></Col>
                        </Row>
                    ))}


                    {/* Measurements that are not in the fitness estimate, but are already fit */}
                    {alreadyFitMeasurementIds.map(measurementId => (
                            <Row key={measurementId}>
                                {/* Human name */}
                                <Col className="text-muted" xs={4} md={6} lg={8}>{getHumanName(measurementId)}</Col>

                                {/* Green checkmark */}
                                <Col xs={4} md={3} lg={2}><FontAwesomeIcon icon={faCheckCircle} className="text-success mx-2" /></Col>

                                <Col xs={4} md={3} lg={2}></Col>
                            </Row>
                        ))
                    }
                </>
            ) : (
                <Loader />
            )}
        </Container>
    );
}

export default FitnessEstimateManyElement;
