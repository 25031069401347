import { t } from "i18next";
import { useCallback, useMemo } from "react";
import useCartData from "../../app/useCartData";
import useCartApi from "../../app/useCartApi";
import { showNotification } from "../../utils/notification";
import { Button } from "react-bootstrap";

function AddManyToCartBlock({ item, measurementIds, disabled, onContinue, onFinish }) {
    const { cart, setCartItems } = useCartData()
    const cartApi = useCartApi();

    const readyToFinish = useMemo(() => item?.fitnessEstimates?.length === measurementIds?.length,
        [item?.fitnessEstimates?.length, measurementIds?.length]);

    const insuitables = useMemo(() => !item?.fitnessEstimates?.length, [item?.fitnessEstimates?.length]);

    const buttonText = useMemo(() => {
        if (!item?.fitnessEstimates?.length || !measurementIds?.length) {
            return t("addManyToCartAdd");
        }

        let s = "";
        if (readyToFinish) {
            s += t("addManyToCartFinish");
        } else {
            s += t("addManyToCartContinue");
        }

        s += " (" + item.fitnessEstimates.length + " " + t("addManyToCartOf") + " " + measurementIds.length + ")";

        return s;
    }, [item, measurementIds, readyToFinish]);

    const buttonVariant = useMemo(() => {
        if (readyToFinish) {
            return "success";
        } else if (insuitables) {
            return "warning";
        } else {
            return "primary";
        }
    }, [insuitables, readyToFinish]);

    const addItems = useCallback(async () => {
        // Add item to the cart for all fitness estimates
        let newCartItems = cart.cartItems;
        let processedMeasurementIds = [];

        if (item?.fitnessEstimates?.length) {
            // Add suitable shoes
            for (const fitnessEstimate of item.fitnessEstimates) {
                const itemWithFitness = {
                    ...item,
                    fitnessEstimate: fitnessEstimate
                };

                const selectedSize = itemWithFitness.fitnessEstimate?.size;
                const selectedInsole = itemWithFitness.fitnessEstimate?.insole;

                // TODO: human name

                newCartItems = cart.addItems(newCartItems, itemWithFitness, selectedSize, selectedInsole);
                processedMeasurementIds.push(fitnessEstimate.measurementId);
            }
        } else {
            // Add all measurements without size
            for (const measurementId of measurementIds) {
                const itemWithMeasurementId = {
                    ...item,
                    measurementId
                }

                const size = "X";
                const insole = null;

                newCartItems = cart.addItems(newCartItems, itemWithMeasurementId, size, insole, null, measurementId);
                processedMeasurementIds.push(measurementId);
            }
        }

        setCartItems(newCartItems);

        // Save the cart
        if (cart.cartId) {
            try {
                await cartApi.save(cart.cartId, newCartItems);
                showNotification(t("Shopping list saved"), "success");
            } catch (error) {
                console.log(error);
                showNotification(t("Error saving shopping list"), "danger");
            }
        }

        // Continue or finish
        if (processedMeasurementIds.length === measurementIds.length) {
            onFinish();
        } else {
            onContinue(processedMeasurementIds);
        }
    }, [cart, item, setCartItems, measurementIds, cartApi, onFinish, onContinue]);

    return (
        <div>
            <div className='d-flex justify-content-end align-items-center'>
                <Button variant={buttonVariant} onClick={addItems} disabled={disabled || !item.fitnessEstimates || !measurementIds}>
                    {buttonText}
                </Button>
            </div>

            {insuitables && (
                <div className='d-flex justify-content-end align-items-center'>
                    <span className='text-warning fst-italic'>{t("addManyToCartInsuitables")}</span>
                </div>
            )}
        </div>
    );
}

export default AddManyToCartBlock;
