import { useCallback, useEffect, useMemo, useState } from "react";
import { apiGetMeasurements } from "../../app/measurementApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../global/Loader";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function MeasurementList({ measurementIds, initMeasurementIds }) {
    const [measurements, setMeasurements] = useState(null);

    const sortedMeasurements = useMemo(() => {
        // Sort measurements by presence in the measurementIds list, then by human name, than by measurement ID
        if (measurements) {
            return [...measurements].sort((a, b) => {
                const aInList = measurementIds && measurementIds.includes(a.measurementId);
                const bInList = measurementIds && measurementIds.includes(b.measurementId);
                if (aInList && !bInList) {
                    return -1;
                } else if (!aInList && bInList) {
                    return 1;
                } else {
                    if (a?.humanInfo?.name < b?.humanInfo?.name) {
                        return -1;
                    } else if (a?.humanInfo?.name > b?.humanInfo?.name) {
                        return 1;
                    } else {
                        if (a.measurementId < b.measurementId) {
                            return -1;
                        } else if (a.measurementId > b.measurementId) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                }
            });
        }
    }, [measurements, measurementIds]);

    const isCompleted = useCallback((measurement) => !measurementIds?.includes(measurement.measurementId), [measurementIds]);

    useEffect(() => {
        if (initMeasurementIds) {
            // Fetch measurements
            apiGetMeasurements(initMeasurementIds)
                .then(ms => setMeasurements(ms))
                .catch(err => {
                    console.log(err);
                    showNotification(t("measurementListFetchFailed") + ": " + err, 'danger');
                });
        }
    }, [initMeasurementIds]);

    return (
        <div className="d-flex flex-column align-items-start" style={{ maxHeight: "400px", overflowY: "auto" }}>
            {sortedMeasurements ? (
                sortedMeasurements.map(m => (
                    <div key={m.measurementId}>
                        {/* Human name or measurement ID */}
                        <span className={"text-truncate " + (isCompleted(m) && "text-muted")}>{m?.humanInfo?.name || t("Measurement") + " " + m.measurementId}</span>

                        {/* Green checkbox for completed measurement */}
                        {isCompleted(m) && <FontAwesomeIcon icon={faCheckCircle} className="text-success mx-2" />}
                    </div>
                )
                )) : (
                <Loader />
            )}
        </div>
    );
}

export default MeasurementList;
