import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import useClientMeasurementApi from "../../app/useClientMeasurementApi";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";

function GroupSelect({ groups, onSelected }) {
    const clientMeasurementApi = useClientMeasurementApi();

    const [fetchedGroups, setFetchedGroups] = useState(null);

    // Fetch groups if not given
    useEffect(() => {
        if (!groups) {
            clientMeasurementApi.getGroups()
                .then(groups => {
                    setFetchedGroups(groups);
                })
                .catch(error => {
                    console.log(error);
                    showNotification(t("measurementsFetchGroupsFailed") + ": " + error, 'danger');
                });
        }
    }, [groups, clientMeasurementApi]);

    const groupsToRender = useMemo(() => groups || fetchedGroups, [groups, fetchedGroups]);

    return (
        <div className="d-flex flex-column my-3 align-items-center" style={{ height: "400px", overflowY: "auto" }}>
            <div className="d-flex w-100 align-items-center">
                <Button
                    variant="link"
                    onClick={() => onSelected(null)}
                ><Trans>clientMeasurementsDefaultGroup</Trans></Button>
            </div>

            {groupsToRender && groupsToRender.length > 0 && groupsToRender.map(group => (
                <div className="d-flex w-100 align-items-center" key={group.group_id}>
                    <Button
                        variant="link"
                        onClick={() => onSelected(group)}
                    >{group.name}</Button>
                </div>
            ))}
        </div>
    );
}

export default GroupSelect;
