import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "react-i18next";
import { showNotification } from "../../utils/notification";
import { t } from "i18next";
import useCartApi from "../../app/useCartApi";
import useCartData from "../../app/useCartData";
import { useCallback, useMemo } from "react";
import { getAvailableSizes, getShoeInsoles } from "../../app/shoeModel";

const AddToCartBlock = ({ item, humanName, selectedSize, setSelectedSize, selectedInsole, disabled }) => {
    const { cart, setCartItems } = useCartData()
    const cartApi = useCartApi();

    const fitnessSize = useMemo(() => item.fitnessEstimate?.size, [item]);

    const insoles = useMemo(() => getShoeInsoles(item), [item]);
    const addToCartInsole = useMemo(() => {
        if (selectedInsole) {
            return selectedInsole;
        } else if (item.fitnessEstimate?.insole?.id && insoles.find(insole => insole.id === item.fitnessEstimate.insole.id)) {
            return item.fitnessEstimate.insole;
        } else if (insoles?.[0]) {
            return insoles[0];
        } else {
            return null;
        }
    }, [item, insoles, selectedInsole]);

    const handleAddToCart = useCallback(() => {
        const newCartItems = cart.add(item, selectedSize, selectedInsole, humanName);
        setCartItems(newCartItems);

        if (cart.cartId) {
            cartApi.save(cart.cartId, newCartItems)
                .then(() => {
                    showNotification(t("Shopping list saved"), "success");
                })
                .catch((error) => {
                    console.log(error);
                    showNotification(t("Error saving shopping list"), "danger");
                })
        }
    }, [cart, cartApi, humanName, item, selectedInsole, selectedSize, setCartItems]);

    const buttonStyle = useMemo(() => {
        if (fitnessSize && fitnessSize === selectedSize) {
            return "btn-success";
        } else if (fitnessSize && fitnessSize !== selectedSize) {
            return "btn-warning";
        } else {
            return "btn-primary";
        }
    }, [fitnessSize, selectedSize]);

    return (
        <div className='d-flex justify-content-between align-items-center'>
            <div className="text-start me-2">
                {item.availableSize && item.availableSize.length > 0 && (
                    <select className="form-select" value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
                        {getAvailableSizes(item).map((size) => (
                            <option key={size} value={size}>{size}{fitnessSize && fitnessSize === size && " ★"}</option>
                        ))}
                    </select>
                )}
            </div>

            {cart.check(item, selectedSize, addToCartInsole) ? (
                <a className={"btn btn-secondary btn-sm float-end"} href="/cart">
                    <FontAwesomeIcon icon={faShoppingBag} className="me-1" />
                    <Trans>Go to shopping list</Trans>
                </a>
            ) : (
                <button className={"btn btn-sm float-end " + buttonStyle} onClick={() => handleAddToCart()} disabled={!selectedSize || disabled}>
                    <FontAwesomeIcon icon={faShoppingBag} className="me-1" />
                    <Trans>Add to shopping list</Trans>
                </button>
            )}
        </div>
    );
};

export default AddToCartBlock;
