import { testCodeDescriptionString } from "../utils/codeDescriptionString";

function fixImageUrl(url) {
    // FIXME: This is a hack
    const baseUrl = process.env.REACT_APP_SHOEDB_URL;
    const baseUrlDev = 'https://dp2.emitech.tech:8443';

    if (baseUrl !== baseUrlDev && url.startsWith(baseUrlDev)) {
        return url.replace(baseUrlDev, baseUrl);
    } else {
        return url;

    }
}

function injectDebugVideo(data) {
    // data.video = [
    //     "https://www.youtube.com/watch?v=a-n8PcN6Wa0"
    // ];
    // data.shortVideo = [
    //     "https://www.youtube.com/watch?v=ZHpqZW12Z7s"
    // ];
    // data.smallShortVideo = [
    //     "https://www.youtube.com/watch?v=ZHpqZW12Z7s"
    // ];

    return data;
}

function fixProtectiveProperties(protectiveProperties) {
    if (typeof (protectiveProperties) === "object" && protectiveProperties.length > 0) {
        return protectiveProperties.filter(prop => testCodeDescriptionString(prop));
    } else {
        return [];
    }
}

function fixShoeModels(data) {
    // Fix image urls
    if (data.shoeModels) {
        data.shoeModels = data.shoeModels.map(shoeModel => {
            if (shoeModel.images) {
                shoeModel.imagesFixed = shoeModel.images.map(fixImageUrl);
            }

            if (shoeModel.smallImages) {
                shoeModel.smallImagesFixed = shoeModel.smallImages.map(fixImageUrl);
            }

            return shoeModel;
        });
    }

    // Inject debug video
    if (data.shoeModels) {
        data.shoeModels = data.shoeModels.map(injectDebugVideo);
    }

    // Fix protective properties
    if (data.shoeModels) {
        data.shoeModels = data.shoeModels.map(shoeModel => {
            if (shoeModel.protectiveProperties) {
                shoeModel.protectiveProperties = fixProtectiveProperties(shoeModel.protectiveProperties);
            }
            return shoeModel;
        });
    }
}

async function apiGetShoeModels({ limit, currentPage, measurementId, measurementIds, searchString, propertiesFilter, sortMode, outputUnfit, signal }) {
    const url = process.env.REACT_APP_SHOEDB_URL + '/api/getShoeModels';
    let requestData = {
        "page": {
            "limit": limit,
            "page": currentPage - 1 // Adjust the page number to start at 0
        }
    };

    requestData.filter = {}
    requestData.filter.properties = {}

    if (searchString) {
        requestData.filter.properties.name = searchString;
    }

    if (propertiesFilter) {
        Object.assign(requestData.filter.properties, propertiesFilter);
    }

    if (measurementId) {
        requestData.measurementId = measurementId;
    } else if (measurementIds) {
        requestData.measurementIds = measurementIds;
    }

    switch (sortMode) {
        case "NAME":
            requestData.sorting = { "type": "NAME", "direction": "ASC" };
            break;
        case "PRICE":
            requestData.sorting = { "type": "PRICE", "direction": "ASC" };
            break;
        case "RATING":
            requestData.sorting = { "type": "RATING", "direction": "DESC" };
            break;
        default:
            // requestData.sorting = { "type": "DEFAULT", "direction": "ASC" };
            break;
    }

    if (outputUnfit) {
        requestData.outputUnfit = outputUnfit;
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOEDB_API_KEY
        },
        body: JSON.stringify(requestData),
        signal: signal
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let data = await response.json();
    
    // Fix models data
    if ("shoeModels" in data) {
        fixShoeModels(data);
    }

    return data;
}

async function apiGetShoeModelsResult(taskId) {
    const url = process.env.REACT_APP_SHOEDB_URL + '/api/getShoeModelsResult?taskId=' + taskId;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOEDB_API_KEY
        },
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let data = await response.json();
    
    // Fix models data
    if ("shoeModels" in data) {
        fixShoeModels(data);
    }

    return data;
}

async function apiGetShoeModel(modelId, measurementId, insoleId, size) {
    let urlSearchParams = new URLSearchParams({
        "modelId": modelId
    });
    if (measurementId) {
        urlSearchParams.append("measurementId", measurementId);
    }
    if (insoleId) {
        urlSearchParams.append("insoleId", insoleId);
    }
    if (size) {
        urlSearchParams.append("size", size);
    }

    const url = process.env.REACT_APP_SHOEDB_URL + '/api/getShoeModel?' + urlSearchParams.toString();
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOEDB_API_KEY
        },
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    // Fix image urls
    let data = await response.json();
    if (data.images) {
        data.imagesFixed = data.images.map(fixImageUrl);
    }

    if (data.smallImages) {
        data.smallImagesFixed = data.smallImages.map(fixImageUrl);
    }

    // Inject debug video
    data = injectDebugVideo(data);

    // Fix protective properties
    if (data.protectiveProperties) {
        data.protectiveProperties = fixProtectiveProperties(data.protectiveProperties);
    }

    return data;
}

function removePropertiesDuplicates(shoeProperties) {
    // iterate all children
    for (let property in shoeProperties) {
        if (typeof shoeProperties[property] === 'object') {
            // remove duplicates
            shoeProperties[property] = [...new Set(shoeProperties[property])];
        }
    }

    return shoeProperties;
}

async function apiGetShoeProperties() {
    const url = process.env.REACT_APP_SHOEDB_URL + '/api/getShoeProperties';
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOEDB_API_KEY
        },
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let data = await response.json();

    // remove properties duplicates
    data = removePropertiesDuplicates(data);

    // Fix protective properties
    if (data.protectiveProperties) {
        data.protectiveProperties = fixProtectiveProperties(data.protectiveProperties);
    }

    return data;
}

export { apiGetShoeModels, apiGetShoeModelsResult, apiGetShoeModel, apiGetShoeProperties };
