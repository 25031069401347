import { useCallback, useEffect, useMemo, useState } from "react";
import { showNotification } from "../../utils/notification";
import ClientApi from "../../app/clientApi";
import { Alert, Button, Col, Collapse, Container, Form, Row } from "react-bootstrap";
import { t } from "i18next";
import { Trans } from "react-i18next";
import useLocalStorageState from "use-local-storage-state";
import ProfilePhoneInput from "./ProfilePhoneInput";
import { isoDateToString } from "../../utils/date";
import ProfileChangePassword from "./ProfileChangePassword";
import useCartData from "../../app/useCartData";
import ProfileChangeEmail from "./ProfileChangeEmail";

function ProfileData() {
    const [sessionId, setSessionId] = useLocalStorageState('sessionId', { defaultValue: null });
    const clientApi = useMemo(() => new ClientApi(sessionId), [sessionId]);
    const [userData, setUserData] = useState(null);

    const email = useMemo(() => userData?.email || '', [userData]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');

    const [phone, setPhone] = useState('');
    const phoneConfirmed = useMemo(() => userData?.phone_confirmed, [userData]);

    const [changeEmailOpen, setChangeEmailOpen] = useState(false);
    const [changePhoneOpen, setChangePhoneOpen] = useState(false);
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);

    const [deleteProfileOpen, setDeleteProfileOpen] = useState(false);
    const [deleteProfileEmail, setDeleteProfileEmail] = useState('');
    const [deleteProfileCodeSent, setDeleteProfileCodeSent] = useState(false);
    const [deleteProfileCode, setDeleteProfileCode] = useState('');

    const { setCartId, setCartItems, setCartName, setCartSubmitted } = useCartData();

    useEffect(() => {
        setFirstName(userData?.first_name || '');
        setLastName(userData?.last_name || '');
        setCompanyName(userData?.company_name || '');
        setPhone(userData?.phone || '');
    }, [userData]);

    const updateUserData = useCallback(async () => {
        try {
            const data = await clientApi.getUserData();
            setUserData(data);
        } catch (error) {
            console.error(error);
            showNotification(t("profileDataFetchError") + ": " + error.message, 'danger');
        }
    }, [clientApi]);

    const saveChanges = useCallback(async () => {
        try {
            const newData = await clientApi.updateUserData({
                firstName,
                lastName,
                companyName,
                phone
            });
            setUserData(newData);
            showNotification(t("profileDataSaved"), 'success');
        } catch (error) {
            console.error(error);
            showNotification(t("profileDataSaveError") + ": " + error.message, 'danger');
        }
    }, [clientApi, firstName, lastName, companyName, phone]);

    const logout = useCallback(async () => {
        try {
            await clientApi.logout();
            setSessionId(null);

            // reset cart
            setCartId(null);
            setCartItems([]);
            setCartName('');
            setCartSubmitted(false);

            window.location.href = '/';
        } catch (error) {
            console.error(error);
            showNotification(t("profileDataLogoutError") + ": " + error.message, 'danger');
        }
    }, [clientApi, setCartId, setCartItems, setCartName, setCartSubmitted, setSessionId]);

    const sendDeleteProfileCode = useCallback(async () => {
        try {
            await clientApi.deleteProfile();
            setDeleteProfileCodeSent(true);
        } catch (error) {
            console.error(error);
            showNotification(t("profileDataCodeSendError") + ": " + error.message, 'danger');
        }
    }, [clientApi]);

    const deleteProfile = useCallback(async () => {
        try {
            await clientApi.deleteProfile(deleteProfileCode);
            setSessionId(null);
            window.location.href = '/';
        } catch (error) {
            console.error(error);
            showNotification(t("profileDataDeleteError") + ": " + error.message, 'danger');
        }
    }, [clientApi, deleteProfileCode, setSessionId]);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await clientApi.getUserData();
                setUserData(data);
            } catch (error) {
                console.error(error);
                showNotification(t("profileDataFetchError") + ": " + error.message, 'danger');
            }
        }

        const timeout = setTimeout(() => {
            fetchData();
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [clientApi]);

    function getTrainingDoneText() {
        if (!userData?.training_done) {
            return t("profileDataTrainingNotDone");
        } else {
            let s = t("profileDataTrainingDone");

            if (userData?.training_date) {
                s += " (" + isoDateToString(userData.training_date) + ")";
            }

            return s;
        }
    }

    return (
        <Container className="mt-3">
            <Row>
                <Col xs={12} md={8} lg={6} className="mx-auto">
                    <h1>{t("profileData")}</h1>

                    {userData?.avatar_url && (
                        <img src={userData.avatar_url} alt="Avatar" className="avatar rounded mt-3" />
                    )}

                    <Form>
                        <Form.Group className="mt-3">
                            <Form.Label>{t("profileDataEmail")}</Form.Label>
                            <Form.Control type="text" value={email} disabled />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>{t("profileDataFirstName")}</Form.Label>
                            <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>{t("profileDataLastName")}</Form.Label>
                            <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>{t("profileDataCompany")}</Form.Label>
                            <Form.Control type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                        </Form.Group>

                        <ProfilePhoneInput clientApi={clientApi} phone={phone} setPhone={setPhone} phoneConfirmed={phoneConfirmed} changeEnabled={!phoneConfirmed || changePhoneOpen} updatePhoneConfirmed={updateUserData} />

                        <Form.Group className="mt-3">
                            <Form.Label>{t("profileDataTraining")}</Form.Label>
                            <Form.Control type="text" value={getTrainingDoneText()} className={userData?.training_done ? "text-success" : "text-warning"} disabled />
                        </Form.Group>
                    </Form>

                    <div className="mt-3">
                        <Button variant="primary" onClick={() => saveChanges()}><Trans>profileDataSave</Trans></Button>
                        <Button variant="secondary" className="ms-3" onClick={() => setChangeEmailOpen((prev) => !prev)}><Trans>profileDataChangeEmail</Trans></Button>

                        {phoneConfirmed && (
                            <Button variant="secondary" className="ms-3" onClick={() => setChangePhoneOpen((prev) => !prev)}><Trans>profileDataChangePhone</Trans></Button>
                        )}

                        {userData?.has_password && (
                            <Button variant="secondary" className="ms-3" onClick={() => setChangePasswordOpen((prev) => !prev)}><Trans>profileDataChangePassword</Trans></Button>
                        )}
                    </div>

                    <Collapse in={changeEmailOpen}>
                        <div>
                            <ProfileChangeEmail clientApi={clientApi} handleEmailChanged={() => {
                                setChangeEmailOpen(false);
                                updateUserData();
                            }} />
                        </div>
                    </Collapse>

                    <Collapse in={changePasswordOpen}>
                        <div>
                            <ProfileChangePassword clientApi={clientApi} handlePasswordChanged={() => setChangePasswordOpen(false)} />
                        </div>
                    </Collapse>

                    <div className="mt-3">
                        <Button variant="outline-danger" style={{ border: 'none' }} onClick={() => logout()}><Trans>profileDataLogout</Trans></Button>
                    </div>

                    <div className="mt-5">
                        <Button variant="outline-danger" style={{ border: 'none' }} size="sm" onClick={() => setDeleteProfileOpen((prev) => !prev)}><Trans>profileDataDelete</Trans></Button>

                        <Collapse in={deleteProfileOpen}>
                            <div>
                                <Alert variant="danger" className="mt-3">
                                    <Trans>profileDataDeleteWarning</Trans>
                                </Alert>

                                <Form.Group className="mt-3">
                                    <Form.Label>{t("profileDataDeleteEmail")}</Form.Label>
                                    <Form.Control type="email" value={deleteProfileEmail} onChange={(e) => setDeleteProfileEmail(e.target.value)} />
                                </Form.Group>

                                {!deleteProfileCodeSent && (
                                    <Button variant="primary"
                                        className="mt-3"
                                        disabled={deleteProfileEmail !== userData?.email}
                                        onClick={() => sendDeleteProfileCode()}
                                    ><Trans>profileDataDeleteSendCode</Trans></Button>
                                )}

                                {deleteProfileCodeSent && (
                                    <>
                                        <Form.Group className="mt-3">
                                            <Form.Label>{t("profileDataDeleteCode")}</Form.Label>
                                            <Form.Control type="text" value={deleteProfileCode} onChange={(e) => setDeleteProfileCode(e.target.value)} />
                                        </Form.Group>

                                        <Button variant="danger"
                                            className="mt-3"
                                            onClick={() => deleteProfile()}
                                        ><Trans>profileDataDelete</Trans></Button>
                                    </>
                                )}
                            </div>
                        </Collapse>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ProfileData;
