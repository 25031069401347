import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import CurrencyFormatter from '../global/CurrencyFormatter';
import { useCallback, useMemo, useState } from 'react';
import StarsRating from './StarsRating';
import { useNavigate } from 'react-router-dom';
import AddToCartBlock from './AddToCartBlock';
import { Trans } from 'react-i18next';
import "../../styles/ShoeCardDetails.scss";
import { t } from 'i18next';
import { getShoeImageUrl, getTypeBrandNameStr, isShoeOutOfStock } from '../../app/shoeModel';
import ReactPlayer from 'react-player/lazy';
import { Badge, Spinner } from 'react-bootstrap';
import useCartData from '../../app/useCartData';
import AddManyToCartBlock from './AddManyToCartBlock';

const ShoeCard = ({ item, measurementId, humanName, measurementIds, cardPlayingModelId, setCardPlayingModelId, allMeasurementIds, onContinue, onFinish }) => {
    const { cart } = useCartData();

    let defaultSize = '';
    if (item.fitnessEstimate) {
        defaultSize = item.fitnessEstimate.size;
    } else if (item.availableSize.length > 0) {
        const cartItem = cart.find(cart.cartItems, item);
        if (cartItem && item.availableSize.includes(cartItem.size)) {
            defaultSize = cartItem.size;
        } else {
            defaultSize = item.availableSize[0];
        }
    }

    const cardImageUrl = useMemo(() => getShoeImageUrl(item, true), [item]);
    const isOutOfStock = useMemo(() => isShoeOutOfStock(item), [item]);

    const [selectedSize, setSelectedSize] = useState(defaultSize);
    const navigate = useNavigate();

    const shortVideo = useMemo(() => {
        if (typeof (item?.smallShortVideo?.[0]) === "string") {
            return item.smallShortVideo[0];
        } else if (typeof (item?.shortVideo?.[0]) === "string") {
            return item.shortVideo[0];
        } else {
            return null;
        }
    }, [item]);

    const getShoeModelUrl = useCallback(() => {
        let urlSearchParams = new URLSearchParams({
            modelId: item.modelId
        });

        if (measurementId) {
            urlSearchParams.append("measurementId", measurementId);
        }

        if (selectedSize) {
            urlSearchParams.append("selectedSize", selectedSize);
        }

        return "/shoeModel?" + urlSearchParams.toString();
    }, [item, measurementId, selectedSize]);

    const navigateToShoeModel = useCallback(() => {
        navigate(getShoeModelUrl(),
            { state: { item: item, selectedSize: selectedSize, measurementId: measurementId, humanName: humanName, measurementIds: measurementIds, allMeasurementIds: allMeasurementIds } });
    }, [allMeasurementIds, getShoeModelUrl, humanName, item, measurementId, measurementIds, navigate, selectedSize]);

    const isPlaying = useMemo(() => cardPlayingModelId === item.modelId, [cardPlayingModelId, item.modelId]);

    return (
        <div className={`card h-100 w-100 ${isOutOfStock ? 'out-of-stock' : ''}`} data-out-of-stock-text={t("outOfStock")}>
            <a href={getShoeModelUrl()} onClick={navigateToShoeModel}>
                {shortVideo ? (

                    <div
                        className='shoe-card'
                        style={{ padding: "1px" }} // NOTE: 1 px padding is needed to make more reliable mouse enter/leave events since inside of the div there is a iframe which captures mouse events
                        onMouseEnter={() => setCardPlayingModelId(item.modelId)}
                        onMouseLeave={() => setCardPlayingModelId(null)}
                        onClick={() => {
                            if (isPlaying) {
                                setCardPlayingModelId(null);
                            } else {
                                setCardPlayingModelId(item.modelId);
                            }
                        }}>
                        <ReactPlayer loop muted playing={isPlaying} url={shortVideo} playsinline fallback={<Spinner animation="border" />} width="100%" height="100%" />
                    </div>
                ) : (
                    <img className="card-img-top shoe-card" src={cardImageUrl} alt={item.name} />
                )}
            </a>

            <div className="card-body">
                {/* Price and rating */}
                <div className='d-flex justify-content-between align-items-baseline'>
                    <h5 className="card-title fw-bold">
                        <CurrencyFormatter amount={item.price} />
                    </h5>
                    {item.fitnessEstimate && (
                        <StarsRating rating={item.fitnessEstimate.fitnessRating} compact={true} />
                    )}
                    {item.fitnessEstimates && (
                        <Badge>{item.fitnessEstimates.length}</Badge>
                    )}
                </div>

                {/* Type, brand and model */}
                <div className="card-text">
                    <div className='d-flex align-items-baseline'>
                        <div className='text-truncate' style={{ textOverflow: 'ellipsis' }}>
                            <a style={{
                                color: 'inherit',
                                textDecoration: 'none',
                                ':hover': {
                                    color: 'inherit',
                                    textDecoration: 'none',
                                },
                            }} href={getShoeModelUrl()} onClick={navigateToShoeModel}>{getTypeBrandNameStr(item)}</a>
                        </div>
                        {item.externalLinks && item.externalLinks.length > 0 && (
                            <div>
                                <a className='ms-2' href={item.externalLinks[0]} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" /></a>
                            </div>
                        )}
                    </div>
                    {/* <div className="text-truncate">{item.shortDescription ? item.shortDescription : "(" + t("no description provided") + ")"}</div> */}
                    {/* <div className='text-truncate text-muted'><small><Trans>Article</Trans>: {item.article}</small></div> */}
                    <div className='text-truncate text-muted'><small><Trans>Model</Trans>: {item.name}</small></div>
                </div>

                {/* Size selector and add to cart button */}
                <div className="card-text my-2">
                    {(measurementIds && "fitnessEstimates" in item) ? (
                        <AddManyToCartBlock item={item} measurementIds={measurementIds} disabled={isOutOfStock} onContinue={onContinue} onFinish={onFinish} />
                    ) : (
                        <AddToCartBlock item={item} humanName={humanName} selectedSize={selectedSize} setSelectedSize={setSelectedSize} disabled={isOutOfStock} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShoeCard;
